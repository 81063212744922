import React, {useState} from "react"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { render } from "react-dom"
import Header from "../components/Header"
import {MenuItemsDE} from "../constants/MenuItems"
import {headerDE} from "../constants/HeaderText"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import CSS from "../components/main.css"
import Intro from "../components/Intro"


// data
export const query = graphql`
  {
    allContentfulPost(filter: {node_locale: {eq: "de"}}, sort: {fields: title}) {
      edges {
        node {
          title
          body {
            raw
          }
        }
      }
    }
    allContentfulIntro(filter: {title: {eq: "IntroDE"}, node_locale: {eq: "de"}})  {
      edges {
        node {
          id
          body {
            raw
          }
        }
      }
    }
  }
`


const Page = ({data, selectedPage, changeSelectedPage, location}) => {

  const posts = data.allContentfulPost.edges
  const intro = data.allContentfulIntro.edges
  const links = data.allContentfulPost.edges.title
  const assetMap = new Map();
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p>{children}</p>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2><span>{children}</span></h2>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className="unstyledList">{children}</ul>
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const asset = assetMap.get(node.data.target.sys.id);
      }
    }
  }
  
  return(
    <>
    <SEO />
    <Layout headerItems={headerDE} posts={posts} options={options} MenuItems={MenuItemsDE} subtitle={'Heilpraktikerin für Psychotherapie'} path={location.pathname}>
      <Intro intro={intro} options={options}/>
    </Layout>
    </>
  )
}



export default Page